import {
    getEmailErrorMessage,
    getPhoneErrorMessage,
    showError,
    hideError,
} from "./errorHandler";
import {APP_SERVER_URL} from "../../../config";
import {sendGtagEvent} from "./gtag";
import {getValueFromBrowser} from "./trackingQueryHook";

export let isError = false;

export const validateField = async (type, payload) => {
    const checkField = async (type, payload) => {
        return fetch(`${APP_SERVER_URL}player/crm/validate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type,
                payload,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    // If response is not ok, parse the response to get the error details
                    return response.json().then(error => {
                        return Promise.reject(error);
                    });
                }
                return response.json();
            })
            .then(response => {
                return {
                    isValid: !!response?.success,
                }
            })
            .catch(error => {
                return {
                    message: error?.message,
                };
            })
    }

    return await checkField(type, payload);
};

export const emailValidation = async (email, error) => {
    const isEmailValid = await validateField('email', email);

    if (isEmailValid?.message) {
        const errorMessage = getEmailErrorMessage(isEmailValid.message);
        if (errorMessage) {
            showError(error, errorMessage);
            isError = true;
        }
    } else {
        hideError(error);
        isError = false;
    }

    return isError;
};

export const phoneValidation = async (phone, error) => {
    const isPhoneValid = await validateField('phone', {
        phone: `+52${phone}`,
        regionCode: "MX",
    });

    if (isPhoneValid?.message) {
        const errorMessage = getPhoneErrorMessage(isPhoneValid.message);
        if (errorMessage) {
            showError(error, errorMessage);
            isError = true;
        }
    } else {
        hideError(error);
        isError = false;
    }

    return isError;
};
