const addYearsToInput = () => {
    const yearElement = document.getElementById('year'); // get the year select
    const df = document.createDocumentFragment(); // create a document fragment to hold the options while we create them
    const years = Array.from({ length: new Date().getFullYear() - 18 - 1920 + 1 }, (_, ind) => {
        return ind + 1920;
    });
    for (let year = 0; year < years.length; year++) {
        const yearStrValue = `${years[year]}`;
        const option = document.createElement('li'); // create the option element
        option.classList.add('select__item');
        option.setAttribute('data-value', yearStrValue);
        option.appendChild(document.createTextNode(yearStrValue)); // set the textContent in a safe way.
        df.appendChild(option); // append the option to the document fragment
    }
    yearElement?.appendChild(df); // append the document fragment to the DOM. this is the better way rather than setting innerHTML a bunch of times (or even once with a long string)
}

const addCustomSelectDateOfBirth = () => {
    const selectScreen = document.querySelectorAll('.js-select-screen');
    const selectItem = document.querySelectorAll('.select__item');

    selectScreen.forEach(item => {
        item.addEventListener('click', e => {
            const screen = e.target.closest('.js-select-screen');
            if (screen) {
                if (screen.classList.contains('is-active')) {
                    screen.nextElementSibling.classList.toggle('is-open');
                    screen.classList.toggle('is-active');
                    return
                }
                selectScreen.forEach(item => {
                    item.classList.remove('is-active');
                    item.nextElementSibling.classList.remove('is-open');
                });
                screen.nextElementSibling.classList.toggle('is-open');
                screen.classList.toggle('is-active');
                if (screen.id === 'screen-year') {
                    screen.nextElementSibling.lastChild.scrollIntoView();
                }
            } else {
                const screenActive = document.querySelector('.js-select-screen.is-active');
                if (screenActive) {
                    screenActive.classList.remove('is-active');
                    screenActive.nextElementSibling.classList.remove('is-open');
                }

            }
        });
    });

    selectItem.forEach(item => {
        item.addEventListener('click', e => {
            const selectedItem = e.target.closest('.select__item');
            if (selectedItem) {
                selectedItem.classList.add('is-active');
                const parentList = selectedItem.closest('.js-select-list'),
                    screen = parentList.previousElementSibling;

                if (screen) {
                    const fieldInnerHTML = selectedItem.innerHTML;
                    if (typeof fieldInnerHTML !== 'undefined' ) {
                        screen.innerHTML = fieldInnerHTML;
                    }
                    const fieldValue = selectedItem.getAttribute('data-value');
                    if (typeof fieldValue !== 'undefined') {
                        screen.setAttribute('data-value', fieldValue);
                    }
                }

                if (parentList) {
                    let items = parentList.querySelectorAll('.select__item');

                    items?.forEach(item => {
                        item.classList.remove('is-active');
                    });

                    if (parentList.classList.contains('is-open')) {
                        parentList.classList.remove('is-open');
                        screen.classList.remove('is-active');
                    }
                }
            }
        });
    });
};

addYearsToInput();
addCustomSelectDateOfBirth();