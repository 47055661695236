const header = {
    en: {
        "header.nav.gamification": "WinReto",
        "header.nav.promotions": "Promotions",
        "header.nav.promotions.casino": "Casino",
        "header.nav.promotions.sport": "Sports",
        "header.nav.casino": "Casino",
        "header.nav.casino.top": "Top",
        "header.nav.casino.new": "New",
        "header.nav.casino.popular": "Popular",
        "header.nav.casino.hot": "Hot",
        "header.nav.casino.cold": "Cold",
        "header.nav.casino.jackpot": "Jackpots",
        "header.nav.casino.megaways": "Megaways",
        "header.nav.casino.buyBonus": "Buy Bonus",
        "header.nav.casino.scraped": "Scraped",
        "header.nav.liveCasino": "Live casino",
        "header.nav.liveCasino.topLiveCasino": "Top live casino",
        "header.nav.liveCasino.roulette": "Roulette",
        "header.nav.liveCasino.blackJack": "Black Jack",
        "header.nav.liveCasino.pocker": "Pocker",
        "header.nav.liveCasino.baccaratAndDice": "Baccarat & Dice",
        "header.nav.liveCasino.gameShow": "Game show",
        "header.nav.sports": "Sports",
        "header.nav.sports.live": "Live sports",
        "header.nav.sports.virtualSports": "Virtual",
        "header.nav.sports.streaming": "Transmisión en vivo",
        "header.nav.sport.live": "Sports Live",
        "header.nav.sport.virtual": "Virtuals",
        "header.nav.winclub": "VIP",
        "header.nav.referral": "Invite your friend",
        "header.nav.download": "Download the app",
        "header.nav.contact": "Contact us",
        "header.nav.contact.item_1": "Live Chat",
        "header.nav.information": "Information",
        "header.nav.information.item_1": "Open An Account",
        "header.nav.information.item_2": "Deposits To Your Account",
        "header.nav.information.item_3": "Bonus Information",
        "header.nav.information.item_4": "Betting Rules",
        "header.nav.calendar": "Calendario Navideno",
        "header.button.signup": "Register",
        "header.button.login": "Login",
        "header.app.title": "Winpot",
        "header.app.descriptionSport": "Sport App",
        "header.app.descriptionCasino": "Casino App",
        "header.app.button": "Download and Play",
        "gamification.menu.title": "WinReto",
        "gamification.menu.overview": "Overview",
        "gamification.menu.casino": "Casino Missions",
        "gamification.menu.sport": "Sport Missions",
        "gamification.menu.store": "Store",
        "gamification.menu.mail": "Mail"
    },
    es: {
        "header.nav.gamification": "WinReto",
        "header.nav.promotions": "Promociones",
        "header.nav.promotions.casino": "Casino",
        "header.nav.promotions.sport": "Deportes",
        "header.nav.casino": "Casino",
        "header.nav.casino.top": "Top Juegos",
        "header.nav.casino.new": "Nuevo",
        "header.nav.casino.popular": "Popular",
        "header.nav.casino.hot": "Ardiente",
        "header.nav.casino.cold": "Frío",
        "header.nav.casino.jackpot": "Jackpots",
        "header.nav.casino.megaways": "Megaways",
        "header.nav.casino.buyBonus": "Comprar bono",
        "header.nav.casino.scraped": "Raspaditos",
        "header.nav.liveCasino": "Casino En Vivo",
        "header.nav.liveCasino.topLiveCasino": "Top En Vivo Juegos",
        "header.nav.liveCasino.roulette": "Ruleta",
        "header.nav.liveCasino.blackJack": "Black Jack",
        "header.nav.liveCasino.pocker": "Póker",
        "header.nav.liveCasino.baccaratAndDice": "Baccarat",
        "header.nav.liveCasino.gameShow": "Shows",
        "header.nav.sports": "Deportes",
        "header.nav.sports.live": "En vivo",
        "header.nav.sports.virtualSports": "Virtuales",
        "header.nav.sports.streaming": "Transmisión en vivo",
        "header.nav.sport.live": "Deportes en vivo",
        "header.nav.sport.virtual": "Virtuales",
        "header.nav.referral": "Invita a un amigo",
        "header.nav.winclub": "VIP",
        "header.nav.download": "Descargar la aplicación",
        "header.nav.contact": "Contacto",
        "header.nav.contact.item_1": "Chat En Vivo",
        "header.nav.information": "Información",
        "header.nav.information.item_1": "Abrir Una Cuenta",
        "header.nav.information.item_2": "Depositos En Tu Cuenta",
        "header.nav.information.item_3": "Informacion Sobre Bonos",
        "header.nav.information.item_4": "Reglas De Apuestas",
        "header.nav.calendar": "Calendario Navideno",
        "header.button.signup": "Regístrate",
        "header.button.login": "Acceder",
        "header.app.title": "Winpot",
        "header.app.descriptionSport": "App de Deporte",
        "header.app.descriptionCasino": "App de Casino",
        "header.app.button": "Descargar y jugar",
        "gamification.menu.title": "WinReto",
        "gamification.menu.overview": "Resumen",
        "gamification.menu.casino": "Casino Misiones",
        "gamification.menu.sport": "Deportes Misiones",
        "gamification.menu.store": "Tienda",
        "gamification.menu.mail": "Bandeja"
    },
};

export default header;
