import {WINPOT_CASINO_URL} from "../utils/consts";
import {AddOrRemoveSpinner} from "../utils/utils";

const handleClickOnLink = (linkHref, linkTarget) => {
    if(linkHref.includes(WINPOT_CASINO_URL) && linkTarget !== "_blank") {
        AddOrRemoveSpinner(true);
    }
}

const links = document.getElementsByTagName('a');
for(const link of links) {
    const linkHref = link.href;
    const linkTarget = link.target;

    link.addEventListener('click', (e) => {
        handleClickOnLink(linkHref, linkTarget);
    });
}