const footer = {
    en: {
        "footer.nav.casino.title": "Casino",
        "footer.nav.casino.item_1": "Casino Home",
        "footer.nav.casino.item_2": "Live Casino",
        "footer.nav.casino.item_3": "New",
        "footer.nav.casino.item_4": "Slots",
        "footer.nav.casino.item_5": "Jackpots",
        "footer.nav.casino.item_6": "Tables",
        "footer.nav.casino.item_7": "Bingo",

        "footer.nav.sport.title": "Sports",
        "footer.nav.sport.item_1": "Sports Home",
        "footer.nav.sport.item_2": "Live Sports",
        "footer.nav.sport.item_3": "Betting Rules",
        "footer.nav.sport.item_4": "Quiniela Rules",

        "footer.nav.promo.title": "Promos",
        "footer.nav.promo.item_1": "Winpot VIP",
        "footer.nav.promo.item_2": "Casino Promos",
        "footer.nav.promo.item_3": "Sports Promos",
        "footer.nav.promo.item_4": "Affiliates",

        "footer.nav.support.title": "Support / Legal",
        "footer.nav.support.item_1": "Terms And Conditions",
        "footer.nav.support.item_2": "Privacy Policy",
        "footer.nav.support.item_3": "General Bonus Policy",
        "footer.nav.support.item_4": "VIP General Information",
        "footer.nav.support.item_5": "FAQ",

        "footer.nav.info.title": "Information",
        "footer.nav.info.item_1": "Blog",
        "footer.nav.info.item_2": "Responsible Gaming",
        "footer.nav.info.item_3": "Open an Account",
        "footer.nav.info.item_4": "Deposit Into Your Account",
        "footer.nav.info.item_5": "Remove Your Canacias",
        "footer.nav.info.item_6": "Bonus Information",
        "footer.nav.info.item_7": "Payment Options",
        "footer.nav.info.item_8": "Verification",
        "footer.nav.info.item_9": "Winpot VIP Info",

        "footer.nav.contacts.title": "Contact",
        "footer.nav.contacts.item_1": "Live Chat",
        "footer.nav.contacts.item_3": "soporte@winpot.mx",

        "footer.nav.license.title": "Licensed by",

        "footer.nav.download.title": "Download in",
        
        "footer.nav.social.title": "Find us in",

        "footer.nav.socialAgents.title": "Find us Agents In",

        "footer.payments.title": "Payment options",

        "footer.info.title": "About Us",
        "footer.info.paragraph_1": "At Winpot.mx, we strive to keep player satisfaction at the forefront of our vision, and continually deliver it with our winning combination of online entertainment services and customer rewards. The Winpot brand develops and provides superior casino offerings, social games, scratch cards and live casinos. Our loyalty tournaments, promotions and incentives aim to outperform the competition again and again. Thanks to software from the industry leader, Wiztechgroup, a. A spectacular experience awaits every customer in every corner. Its intuitive design, its graphics, its captivating sounds and, above all, its great accessibility give it a truly attractive experience. Register your free account and become a Casino winner today! We use certain browser cookies in an effort to improve your experience with us. By using this site, you acknowledge and agree to this practice.",
        "footer.info.paragraph_2": "Winpot © 2006-2024. All rights reserved.\nThe winpot.mx domain is operated by the company “Winpot Online América S.A. de C.V.”, operator of “Pur Umazal Tov, S.A. de C.V.” ” under the protection of permit DGJS/DGAFJ/DCRCA/P-08/2014, with authorization from the General Directorate of Games and Raffles DGJS/393/2018, DGJS/1130/2022, DGJS/1681/2023 and DGJS/1807/2023 .\nAccess to online gambling websites is prohibited in certain countries. The player is responsible for ensuring that he or she does not break any laws when accessing the Winpot website. Play responsibly for entertainment, fun and recreation. offers independent sports betting services and is not affiliated with the sports teams, event organizers or players that appear on its website.\nAccess to the website is prohibited for those under 18 years of age.\nAttention! be addictive and is not suitable for solving financial problems. Please read the terms and conditions and gamble responsibly Winpot is a registered trademark.\nProhibited for minors.",
    },
    es: {
        "footer.nav.casino.title": "Casino",
        "footer.nav.casino.item_1": "Casino Hogar",
        "footer.nav.casino.item_2": "Casino En Vivo",
        "footer.nav.casino.item_3": "Nuevo",
        "footer.nav.casino.item_4": "Slots",
        "footer.nav.casino.item_5": "Jackpots",
        "footer.nav.casino.item_6": "Mesas",
        "footer.nav.casino.item_7": "Bingo",

        "footer.nav.sport.title": "Deportes",
        "footer.nav.sport.item_1": "Deportes Hogar",
        "footer.nav.sport.item_2": "Deportes En Vivo",
        "footer.nav.sport.item_3": "Reglas De Apuestas",
        "footer.nav.sport.item_4": "Reglas De Quiniela",

        "footer.nav.promo.title": "Promos",
        "footer.nav.promo.item_1": "Winpot VIP",
        "footer.nav.promo.item_2": "Casino Promos",
        "footer.nav.promo.item_3": "Deportes Promos",
        "footer.nav.promo.item_4": "Afiliados",

        "footer.nav.support.title": "Soporte / Legal",
        "footer.nav.support.item_1": "Terminos Y Condiciones",
        "footer.nav.support.item_2": "Politica De Privacidad",
        "footer.nav.support.item_3": "Politica General De Bono",
        "footer.nav.support.item_4": "Información General VIP",
        "footer.nav.support.item_5": "FAQ",

        "footer.nav.info.title": "Información",
        "footer.nav.info.item_1": "Blog",
        "footer.nav.info.item_2": "Juego Responsable",
        "footer.nav.info.item_3": "Abrir Una Cuenta",
        "footer.nav.info.item_4": "Deposito En Tu Cuenta",
        "footer.nav.info.item_5": "Retirar Tus Ganancias",
        "footer.nav.info.item_6": "Informacion Sobre Bonos",
        "footer.nav.info.item_7": "Opciones De Pago",
        "footer.nav.info.item_8": "Verificación",
        "footer.nav.info.item_9": "Información Winpot VIP",

        "footer.nav.contacts.title": "Contacto",
        "footer.nav.contacts.item_1": "Chat En Vivo",
        "footer.nav.contacts.item_3": "soporte@winpot.mx",

        "footer.nav.license.title": "Licenciado por",

        "footer.nav.download.title": "Descargar en",

        "footer.nav.social.title": "Encuéntranos en",

        "footer.nav.socialAgents.title": "Encuéntranos Agents En",

        "footer.payments.title": "Opciones De Pago",

        "footer.info.title": "Sobre Nosotros",
        "footer.info.paragraph_1": "En Winpot.mx, nos esforzamos por mantener la satisfacción del jugador en la vanguardia de nuestra visión, y la proporcionamos continuamente con nuestra combinación ganadora de servicios de entretenimiento en línea y recompensas para el cliente. La marca Winpot desarrolla y proporciona ofertas superiores de casino, juegos sociales, tarjetas de rascar y casinos en vivo. Nuestros torneos de fidelidad, promociones e incentivos buscan superar a la competencia una y otra vez. Gracias al software del líder del sector, Wiztechgroup, una experiencia espectacular espera a todos los clientes en cada rincón. Su diseño intuitivo, sus gráficos, sus sonidos cautivadores y, sobre todo, su gran accesibilidad le confieren una experiencia realmente atractiva. Registra tu cuenta gratuita y conviértete en un ganador del Casino hoy mismo! Utilizamos ciertas cookies del navegador en un esfuerzo por mejorar su experiencia con nosotros. Al utilizar este sitio, Usted reconoce y acepta dicha práctica.",
        "footer.info.paragraph_2": "Winpot © 2006-2024. Todos los derechos reservados.\nEl dominio winpot.mx es explotado por la empresa “Winpot Online América S.A. de C.V.”, operadora de “Pur Umazal Tov, S.A. de C.V.” bajo el amparo del permiso DGJS/DGAFJ/DCRCA/P-08/2014, con autorización de la Dirección General de Juegos y Sorteos DGJS/393/2018, DGJS/1130/2022, DGJS/1681/2023 y DGJS/1807/2023.\nEl acceso a sitios web de juegos online con dinero está prohibido en ciertos países. El jugador es responsable de asegurarse de que no infringe ninguna ley al acceder al sitio web de Winpot. Juega de manera responsable como entretenimiento, diversión y esparcimiento. Winpot ofrece servicios de apuestas deportivas independientes y no está afiliado a los equipos deportivos, organizadores de eventos o jugadores que aparecen en su sitio web.\nEl acceso al sitio web esta prohibido para los menores de 18 años.\n¡Atención! El juego puede ser adictivo y no es adecuado para resolver problemas financieros. Lea los términos y condiciones y apueste de manera responsable. Winpot es una marca registrada.\nProhibido para menores.",
    },
};

export default footer;
